import React, { useState, useEffect } from "react";
import ConnectorCanvasComponent from "./ConnectorCanvasComponent";
import { _deref } from "../utils/json";
import Icons from "../ui_component_library/Icons";
import { get_sku_details_core_format, get_sku_display_url } from "../utils/sku";
import IButton from "../ui_component_library/IButton";
import { parse_expression_interface, validate_expression_interface } from "../utils/expression";
import { Tooltip } from "antd";

const default_new_applicator_struct = {
    name: "New connector",
    application_method: "free",
    applicator_type: "dowel_hinge",
    repeat_distance: 50,
    sku_id: "",
    offsets: []
}

export const all_connector_types = [
    {
        id: "dowel_hinge",
        name: "Dowel/Hinge",
    },
    {
        id: "single_panel_connector",
        name: "Single Panel Connector",
    },
    {
        id: "cabinet_connector",
        name: "Cabinet Connector",
    }
]

const ModifyConnector = ({ mode, connector_type, curr_applicator, set_curr_applicator, sku_selection_onclick }) => {

    const [ sku_details, set_sku_details ] = useState({});

    const available_application_methods = [
        { id: "free", name: "Fixed Quantity Free placement" },
        { id: "equal_distance", name: "Fixed Quantity Equal Placement" },
        { id: "center_even", name: "Center Even" },
        { id: "center_odd", name: "Center Odd" }
    ];

    const [panel_width, set_panel_width] = useState(500);
    const [panel_depth, set_panel_depth] = useState(500);
    const [panel_thickness, set_panel_thickness] = useState(30);

    useEffect(() => {
        if(mode == "add" && connector_type){
            update_property("applicator_type", connector_type);
        }
    }, []);

    const get_filtered_offsets = (from_value, from_value1) => {
        //returns array of numbers if only 1D is passed. otherwise returns points
        var result = [];
        
        //for hinges
		var alternate_from_values = {
			"front": "top",
			"back": "bottom"
		}

        for (var i = 0; i < curr_applicator.offsets.length; i++) {
            var condition = (!from_value) || (from_value && curr_applicator.offsets[i].from == from_value) || (!from_value1 && from_value && curr_applicator.offsets[i].from == alternate_from_values[from_value]);
            var condition1 = (!from_value1) || (from_value1 && curr_applicator.offsets[i].from1 == from_value1);
            if (condition && condition1) {
                if (from_value && from_value1) {
                    result.push({
                        x: curr_applicator.offsets[i].distance1,
                        y: curr_applicator.offsets[i].distance
                    })
                } else {
                    result.push(curr_applicator.offsets[i].distance)
                }
            }
        }

        return result;
    }

    const parse_offset_value = (val) => {
        var params = { panel_width, panel_depth, panel_thickness, total_length: panel_width };

        var new_val = 0;
        if (!isNaN(Number(val))) {
            new_val = Number(val);
        } else if (val) {
            var is_valid = (validate_expression_interface(String(val), params) == "success");
            if (is_valid) {
                new_val = parse_expression_interface(String(val), params);
            }
        }

        return new_val;
    }

    const sort_offsets = (offsets) => {
        offsets.sort((a, b) => {
            if ((a.from == "front" || a.from == "top") && (b.from == "back" || b.from == "bottom")) {
                return -1;
            } else if ((a.from == "back" || a.from == "bottom") && (b.from == "front" || b.from == "top")) {
                return 1;
            } else if (a.from1 == "left" && b.from1 == "right") {
                return -1;
            } else if (a.from1 == "right" && b.from1 == "left") {
                return 1;
            } else {
                return parse_offset_value(a.distance) - parse_offset_value(b.distance);
            }
        });
    }

    const add_or_remove_connector = (mode, from) => {
        var applicator_clone = _deref(curr_applicator);
        var from1;
        if (from.includes("_")) {
            var split = from.split("_");
            from = split[0];
            from1 = split[1];
        }

        var remove_index = -1;
        var connector_before_sort;
        if (from == "index" && !isNaN(Number(from1))) {
            remove_index = Number(from1);
            connector_before_sort = applicator_clone.offsets[remove_index];
        }

        sort_offsets(applicator_clone.offsets);

        if (connector_before_sort) {
            remove_index = window._.findIndex(applicator_clone.offsets, (o) => o == connector_before_sort);
        }

        var filtered_offsets = get_filtered_offsets(from, from1);

        if (mode == "add") {
            var new_connector = { from: from };
            if (from1) {
                var last_offset_x = filtered_offsets.length > 0 ? (parse_offset_value(filtered_offsets[filtered_offsets.length - 1].x)) : 0;
                var last_offset_y = filtered_offsets.length > 0 ? (parse_offset_value(filtered_offsets[filtered_offsets.length - 1].y)) : 0;
                new_connector.from1 = from1;
                new_connector.distance1 = last_offset_x + 50; new_connector.distance1 = String(new_connector.distance1);
                new_connector.distance = last_offset_y + 50; new_connector.distance = String(new_connector.distance);
                new_connector.panel_face = "bottom";
            } else {
                var last_offset = filtered_offsets.length > 0 ? (parse_offset_value(filtered_offsets[filtered_offsets.length - 1])) : 0;
                new_connector.distance = last_offset + 50; new_connector.distance = String(new_connector.distance);
            }
            applicator_clone.offsets.push(new_connector);
        } else if (mode == "remove") {
            if (remove_index == -1) {
                //for hinges
                var alternate_from_values = {
                    "front": "top",
                    "back": "bottom"
                }

                for (var i = 0; i < applicator_clone.offsets.length; i++) {
                    var condition = (!from) || (from && applicator_clone.offsets[i].from == from) || (!from1 && from && applicator_clone.offsets[i].from == alternate_from_values[from]);;
                    var condition1 = (!from1) || (from1 && applicator_clone.offsets[i].from1 == from1);
                    var condition2 = applicator_clone.offsets[i].from == "not_set" || applicator_clone.offsets[i].from == "";

                    if (condition2 || (condition && condition1)) {
                        remove_index = i;
                    }
                }
            }

            if (remove_index != -1) {
                window._.pullAt(applicator_clone.offsets, remove_index);
            }
        }

        set_curr_applicator(applicator_clone);
    }

    const update_property = (property, val) => {
        var applicator_clone = _deref(curr_applicator);
        if(property == "applicator_type"){
            applicator_clone.applicator_type = val;
        }
        if (property == "repeat_distance" || property == "min_start_distance" || property == "min_end_distance") {
            if (isNaN(Number(val))) {
                return;
            }

            applicator_clone[property] = Number(val);
        } else if (property == "name") {
            applicator_clone.name = val;
        } else if (property == "application_method") {
            var old_application_method = applicator_clone.application_method;
            var new_application_method = val;
            if(old_application_method == "equal_distance" && new_application_method != "equal_distance"){
                var data_needs_cleanup = false;
                for(var i=0;i<applicator_clone.offsets.length;i++){
                    if(applicator_clone.offsets[i].distance == undefined || applicator_clone.offsets[i].distance == "" || applicator_clone.offsets[i].distance == "not_set"){
                        data_needs_cleanup = true;
                        break;
                    }
                }
                if(data_needs_cleanup){
                    var front_positions = [];

                    // var repeat_distance = Math.floor(panel_width/(curr_applicator.offsets.length+1));
                    // for(var i=0;i<curr_applicator.offsets.length;i++){
                    //     front_positions.push((i+1)*repeat_distance);
                    // }

                    var diff = Math.floor(panel_width/(2*curr_applicator.offsets.length));
                    var last_equi_pos = diff;
                    for(var i=0;i<curr_applicator.offsets.length;i++){
                        front_positions.push(last_equi_pos);
                        last_equi_pos += 2*diff;
                    }
                    
                    applicator_clone.offsets = [];
                    for (var i = 0; i < front_positions.length; i++) {
                        applicator_clone.offsets.push({ from: "front", distance: front_positions[i] });
                    }
                }
            }
            applicator_clone.application_method = val;

        }

        set_curr_applicator(applicator_clone);
    }

    const update_offsets = ({ idx, from, from1, curr_from, val }) => {
        var params = { panel_width, panel_depth, panel_thickness, total_length: panel_width };

        val = String(val);

        if (isNaN(Number(val)) && (validate_expression_interface(val, params) == "failure")) {
            return;
        }


        var applicator_clone = _deref(curr_applicator);

        var curr_index = 0;
        
        var alternate_from_values = {
            "front": "top",
            "back": "bottom"
        }

        for (var i = 0; i < applicator_clone.offsets.length; i++) {
            var condition = (!from) || (from && applicator_clone.offsets[i].from == from) || (!from1 && from && applicator_clone.offsets[i].from == alternate_from_values[from]);
            var condition1 = (!from1) || (from1 && applicator_clone.offsets[i].from1 == from1);

            if (condition && condition1 && curr_index == idx) {
                if (curr_from == from) {
                    applicator_clone.offsets[i].distance = val;
                } else if (curr_from == from1) {
                    applicator_clone.offsets[i].distance1 = val;
                }
            }

            if (condition && condition1) {
                curr_index += 1;
            }
        }
        set_curr_applicator(applicator_clone);
    }

    const canvas_ui_action_callback = (activeElement) => {
        if (activeElement.object == "repeat_distance" || activeElement.object == "min_start_distance" || activeElement.object == "min_end_distance") {
            update_property(activeElement.object, activeElement.val);
        }else if (activeElement.object == "offset") {
            update_offsets(activeElement);
        }else if (activeElement.object.includes("add_button_") || activeElement.object.includes("remove_button_")) {
            var split = activeElement.object.split("_button_");
            add_or_remove_connector(split[0], split[1]);
        }else if(activeElement.object.includes("switch_face_")){
            var split = activeElement.object.split("switch_face_");
            var index = split[1];
            var applicator_clone = _deref(curr_applicator);
            applicator_clone.offsets[index].panel_face = applicator_clone.offsets[index].panel_face == "top" ? "bottom" : "top";
            set_curr_applicator(applicator_clone);
        }
    }

    useEffect(() => {
        if(curr_applicator.sku_id){
            if((curr_applicator.sku_id != sku_details.id)){
                (async() => {
                    let _sku_details = await (get_sku_details_core_format(curr_applicator.sku_id))
                    set_sku_details(_sku_details)
                })()
            }
        }else{
            set_sku_details({})
        }
    }, [curr_applicator]);

    return (
        <div className='flex_column inf-gap-4'>
            <div className="inf-flex inf-flex-col inf-gap-1">
                <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Connector Name</div>
                <input className="cam_applicator_input" value={curr_applicator.name} onChange={(e) => update_property("name", e.target.value)} type="text" />
            </div>
            
            {
                // mode == "add" && !connector_type ?
                <div className="inf-flex inf-flex-col inf-gap-1">
                    <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Applicable Scenario Type</div>
                    <select disabled={mode !== "add"} className="cam_applicator_input" type='select' value={curr_applicator.applicator_type} onChange={(e) => update_property("applicator_type", e.target.value)}>
                        {all_connector_types.map(connector_type_option => (
                            <option value={connector_type_option.id}>{connector_type_option.name}</option>
                        ))}
                    </select>
                </div>
                // : ''
            }

            <div className="inf-flex inf-flex-col inf-gap-1">
                <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Connector Sku</div>
                {
                    curr_applicator.sku_id == "" ?
                        <div className="flex_center inf-gap-2 inf-p-6 inf-border cp rounded " onClick={sku_selection_onclick}>
                            <Icons name={"add"} />
                            <div>Select SKU</div>
                        </div>
                        :
                        <div className='flex_between inf-border inf-p-2 rounded'>
                            <div className='flex_property inf-gap-2'>
                                <img style={{ height: '64px' }} src={get_sku_display_url(sku_details && sku_details.display_pic)} />
                                {sku_details && sku_details.sku_name || 'N/A'}
                            </div>
                            <IButton ghost={true} onClick={sku_selection_onclick}>Change</IButton>
                        </div>

                }
            </div>
            
            <div className='flex_column inf-p-4 inf-gap-4' style={{ backgroundColor: "#EAECF0", borderRadius: '8px' }}>
                    {
                        curr_applicator.applicator_type == "dowel_hinge" ?
                            <div className="inf-flex inf-flex-col inf-gap-1">
                                <div style={{ whiteSpace: 'nowrap', fontWeight: 500 }}>Placement Setting</div>
                                <div className="flex_property">
                                    <select className="cam_applicator_input" style={{width:"100%"}} type='select' value={curr_applicator.application_method} onChange={(e) => update_property("application_method", e.target.value)}>
                                        {available_application_methods.map(o => (
                                            <option value={o.id}>{o.name}</option>
                                        ))}
                                    </select>
                                    {curr_applicator.application_method.includes("center")?(
                                        <Tooltip trigger={['click']} placement="bottomRight" overlayStyle={{width: '400px', maxWidth: 'unset'}} overlayInnerStyle={{maxHeight: '500px', overflow: 'auto'}} title={
                                            <div className="flex_column inf-gap-2">
                                                Required parameters:   Repeat distance (starting from center), Minimum distance from start, Minimum Distance from End
                                            </div>
                                            }>
                                            <Icons className={'inf-p-1'} onClick={(e) => e.stopPropagation()} name={'info'}></Icons>
                                        </Tooltip>
                                    ):''}
                                </div>
                            </div>
                            : ''
                    }
                <div style={{ position: "relative", textAlign: "center" }}>
                    <ConnectorCanvasComponent
                        curr_applicator={curr_applicator}
                        with_controller={true}
                        ui_action_callback={canvas_ui_action_callback}
                        canvas_width={curr_applicator.applicator_type == "dowel_hinge" ? 500 : 400} canvas_height={curr_applicator.applicator_type == "dowel_hinge" ? 300 : 400}
                        panel_width={panel_width} panel_depth={panel_depth} panel_thickness={panel_thickness}
                    />
                </div>
                {curr_applicator&&curr_applicator.applicator_type == "single_panel_connector" ? (<div style={{display:"block",position:"relative"}}>
                    <div style={{ position: "absolute", bottom: "5px", right: "5px", display: "flex",flexFlow:"column", gap: "8px" }}>
                        <div style={{display:"grid",gridTemplateColumns: "1fr 2fr",gap:4,alignItems:"center"}}>
                            <div style={{ width: "100%", height: "2px", backgroundColor: "black" }}></div>
                            <div>Connectors on Top side</div>
                        </div>
                        <div style={{display:"grid",gridTemplateColumns: "1fr 2fr",gap:4,alignItems:"center"}}>
                        <div style={{ 
                            width: "100%", 
                            height: "2px", 
                            borderTop: "2px dashed black",
                            backgroundColor: "transparent"
                        }}></div>
                            <div>Connectors on Bottom side</div>
                        </div>
                    </div>
                </div>) : ''}
            </div>
        
        </div>
    )
}

export default ModifyConnector;