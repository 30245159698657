import React, { useState, useEffect } from 'react';
import {Input,Dropdown, DropdownItem, DropdownToggle, DropdownMenu,Row, Col, Modal, Card, Button, NavItem } from "reactstrap";
import { COLORS } from '../../utils/color';
// import './style.css';


const Edit_elevation_view_Modal = ({ open, handle_close, active_elevation_view }) => {

    const [ updated_elevation_view_name, set_updated_elevation_view_name ]=useState('')

    const on_click_close = () => {
        set_updated_elevation_view_name('')
        handle_close()
    }

    const submit_values = () => {
        handle_close()
        window.Module.updateElevationCameraNameById(active_elevation_view.id,updated_elevation_view_name);
        console.log(updated_elevation_view_name);
        console.log(active_elevation_view.id);
        //write update name code here
    }

    useEffect(() => {
        if(open && active_elevation_view){
            set_updated_elevation_view_name(active_elevation_view.name);
        }
    },[open])

    return(
        <Modal className="modal-dialog-centered" size="md" isOpen={open} toggle={on_click_close}>
            <Card className="bg-secondary shadow border-0">
                <Row>
                    <Col style={{height:'54px', width:'100%', fontSize:'18px', display:'flex', alignItems:'center', justifyContent:'space-between', backgroundColor: COLORS.modal_heading , color:COLORS.modal_header_color}}>
                        <span>Edit Elevation View Name</span>
                        <span style={{cursor:'pointer'}} onClick={on_click_close}>×</span>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height:'186px', width:'100%', backgroundColor: COLORS.white, padding:'20px 24px'}}>
                        <Row>
                            <Col style={{width:'100%', display:'flex', alignItems:'center', height:'28px', marginBottom:'10px'}}>
                                <span style={{flex:2, fontSize:'14px'}}>Elevation View Name*</span>
                                <Input style={{flex:3, height:"25px", border:0, borderBottom:'1px solid #ced4da'}}  outlined='false' type="text" value={updated_elevation_view_name} onChange={(e) => set_updated_elevation_view_name(e.target.value) } />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:'center',marginTop:'30px'}}>
                                <Button onClick={submit_values} style={{backgroundColor:COLORS.mainpage_locale, padding:'8px 30px',boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)', border:0}} type="button" >Update</Button>
                            </Col>  
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Modal>
        
    )    
}

const Single_elevation_view = ({update_view, item, on_select, submit_for_delete_confirm, open_edit_modal, component_name, display_name, toggle}) => {

    const on_click_delete = (id,e) => {
        console.log(id);
        window.Module.delete_elevation_camera_by_id(id);
        update_view();
        console.log('rrffeff')
        //write delete code here
    }

    const on_click_edit = (e) => {
        // e.stopPropagation();
        open_edit_modal(item)
        console.log('rrffeff')
        //write delete code here
    }

    return(
        <div id={item.id} onClick={()=>{on_select(item.id);}} className='dropdown_visibility_on_hover' style={{fontSize:'12px', display:'flex', alignItems:'center',justifyContent:'space-between', minHeight:'36px'}}>
            <span className='dropdown_visibility_on_hover' style={{cursor:'pointer', flex:5, padding:'4px 10px', wordBreak:'break-word', whiteSpace:'break-spaces'}} >{item.name}</span>
            {display_name ==='Elevation' && item.name!='Add Elevation View'?
                <span style={{flex:1, marginLeft:'10px', display:'flex', alignItems:'center',justifyContent:'flex-end',padding:'4px 10px'}}>
                    <i style={{cursor:'pointer', marginRight:'10px'}} onClick={(e)=>on_click_edit(e)} className='fa fa-pencil dropdown_item_hide'/>
                    <i style={{cursor:'pointer'}} onClick={(e)=>{submit_for_delete_confirm(()=>on_click_delete(item.id), ()=>{}, 'Elevation View will be deleted permanently.')}} className='fa fa-trash dropdown_item_hide'/>
                </span>
                :''
            }
        </div>
    )
}

const NavBar_Dropdown_Left = ({ id, update_view, className, display_name, options, before_open, on_select , submit_for_delete_confirm}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [open_edit_name_modal, set_open_edit_name_modal] = useState(false);
    const [active_elevation_view, set_active_elevation_view] = useState('');

    useEffect(() => {
        if(dropdownOpen && before_open){
            before_open();
        }
    },[dropdownOpen])

    const open_edit_modal = (active) => {
        set_open_edit_name_modal(true)
        set_active_elevation_view(active)
    }
    const close_edit_modal = () => {
        set_open_edit_name_modal(false)
    }

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <React.Fragment>
        <Edit_elevation_view_Modal open={open_edit_name_modal} handle_close={close_edit_modal} active_elevation_view={active_elevation_view} />
                <Dropdown id={id} style={{/* background: 'linear-gradient(to right, transparent, #FFFFFF30)', */ borderTopRightRadius:'15px', borderBottomRightRadius:'15px', padding:'8px', display:'flex', alignItems:'center', height:'30px',maxWidth:400}} nav isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle nav className={className} style={{fontSize:'12px', padding:'0px',overflow:"hidden",textOverflow:"ellipsis"}}>
                        <div className='flex_center'>
                            <div style={{flex: 1, minWidth: '40px'}} className='lines1_elipsis' title={display_name}>{display_name}</div>
                            <i style={{fontSize:'12px', padding: '2px'}} className='fa fa-caret-down ml-1'></i>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu style={{padding:'4px', maxHeight:'280px', overflow:'auto', maxWidth: '400px'}}>
                        {options && options.map((item,idx) => (
                            <DropdownItem key={idx} style={{cursor:'auto',padding:0}}>
                                <Single_elevation_view update_view={update_view} item={item} on_select={on_select} submit_for_delete_confirm={submit_for_delete_confirm} open_edit_modal={open_edit_modal} display_name={display_name} toggle={toggle}/>
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
        </React.Fragment>
    );
}



export default NavBar_Dropdown_Left;


/* <li id="editing_building_nav" className="dropdown navfade" style={{paddingTop:'12px',paddingLeft: '3px'}}>
   <a className="dropdown-toggle blue_hover" data-toggle="dropdown" style={{display:'flex',padding:'0px',paddingLeft:'10px'}}>
       <span style={{color:'white',cursor:'pointer'}}>Building</span>
       <span><i className="fa fa-chevron-right" style={{color: 'white',margin:'auto auto auto 5px'}}></i></span>  
   </a>
   <ul className="dropdown-menu" style={{border: '1px solid black'}}>
       {([2,2,2,2]).map((o,idx) => <li role="presentation" ng-repeat="building in design_buildings"><a style={{cursor:'pointer'}} tabindex="-1">Yo</a></li>)}
   </ul>
</li>*/