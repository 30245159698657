import React, { useState, useEffect, isValidElement } from 'react';
import { Modal, Navbar, Button, Input, Card, CardBody, CardHeader, Col, Nav, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ModalHeader, ModalBody, NavItem, ModalFooter} from "reactstrap";
import './style.css'
const ISearch = ({classNameOuter, classnameInner, list, set_list, placeholder, search_parameters, style_outer, style_inner, getSearchString, ...props}) => {
    const [search_string, set_search_string] = useState('');

    useEffect(() => {
        if(list && list.length && set_list){
            if (search_string) {
                if (search_parameters && search_parameters.length) {
                    // console.log()
                    set_list(
                        list.filter(x => {
                            let result = 0
                            search_parameters.map(search_value => {
                                if (x && x[search_value] && x[search_value].toLowerCase().includes(search_string.toLowerCase())) {
                                    result = 1
                                }
                            })
                            return result
                        })
                    )
                }
                else {
                    set_list(list.filter(x => x && x.toLowerCase().includes(search_string.toLowerCase())))
                }
            } else {
                set_list(list)
            }
        }else if(set_list){
            set_list([])
        }
    }, [search_string, list]);

    useEffect(() => {
        if(getSearchString){
            getSearchString(search_string)
        }
    }, [search_string]);

    // useEffect(() => {
    //     if(list.length){
    //         console.log("list search", list[0][search_value], search_value)
    //     }
    // }, [list]);

    const clear_search = () => {
        set_search_string('')
    }
    return (
        <div className={classNameOuter? classNameOuter : 'infurnia_search_component_container_ideal'} style={{...style_outer}} {...props}>
            <span><i style={{paddingLeft:'12px', paddingBottom:'3px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-search'/></span>
            <input className={classnameInner? classnameInner :'infurnia_search_component_inner_container'} style={{...style_inner}} placeholder= {placeholder ? placeholder :'Type to Search'} type='text' value={search_string} onChange={(e) => set_search_string(e.target.value)}></input>
            <span className='hover_blue' style={{paddingRight:'12px', cursor:'pointer', fontSize:'10px', color:'#9aa5b5', visibility:search_string ? 'visible':'hidden', fontStyle:'italic'}} onClick={clear_search}>Clear</span>
            {/* <span><i style={{paddingRight:'12px', fontSize:'12px', color:'#9aa5b5'}} className='fa fa-times' onClick={clear_search}/></span> */}
        </div>
    )
}

export default ISearch