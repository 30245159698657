import general_fetch from "../../utils/fetch";
import build_path from "./path";

export const get_sku_display_url = (display_pic) => {
    var base_path = display_pic;
    if (!base_path) {
        return "";
    }
    if (window.portal === "design") {
        return build_path(window.Module.API.get_assets_path(), base_path);
    } else if (window.portal === "admin") {
        return build_path(window.config.static_path, base_path);
    }
}

export function loadFromAdminJson(skuDataRef, skuId) {
    let final_sku_details = {};
    try {
        if (typeof skuDataRef.component_type === 'string') {
            final_sku_details.component_type = skuDataRef.component_type;
        }

        if (typeof skuDataRef.user_component_host_type === 'string') {
            final_sku_details.user_component_host_type = skuDataRef.user_component_host_type;
        }

        if (skuDataRef.display_pic && typeof skuDataRef.display_pic.image === 'string') {
            final_sku_details.display_pic = skuDataRef.display_pic.image;
        }

        if (skuDataRef.sku_group && typeof skuDataRef.sku_group.name === 'string') {
            final_sku_details.sku_group_name = skuDataRef.sku_group.name;
        }

        if (typeof skuDataRef.id === 'string') {
            final_sku_details.id = skuDataRef.id;
        }

        if (typeof skuDataRef.name === 'string') {
            final_sku_details.sku_name = skuDataRef.name;
        }

        if (typeof skuDataRef.removed_by_owner === 'boolean') {
            final_sku_details.orphan = skuDataRef.removed_by_owner;
        }

        if (typeof skuDataRef.serial_number === 'number') {
            final_sku_details.sku_serial_number = skuDataRef.serial_number;
        }

        if (typeof skuDataRef.model_no === 'string') {
            final_sku_details.model_no = skuDataRef.model_no;
        }

        if (skuDataRef.Brand && typeof skuDataRef.Brand.name === 'string') {
            final_sku_details.brand = skuDataRef.Brand.name;
        }

        if (skuDataRef.Brand && typeof skuDataRef.Brand.id === 'string') {
            final_sku_details.brand_id = skuDataRef.Brand.id;
        }

        if (typeof skuDataRef.width === 'number') {
            final_sku_details.sku_width = skuDataRef.width;
        }

        if (typeof skuDataRef.depth === 'number') {
            final_sku_details.sku_depth = skuDataRef.depth;
        }

        if (typeof skuDataRef.height === 'number') {
            final_sku_details.sku_height = skuDataRef.height;
        }

        if (typeof skuDataRef.material_id === 'string') {
            final_sku_details.material_id = skuDataRef.material_id;
        }

        if (skuDataRef.pricing_from && skuDataRef.pricing_from !== null && typeof skuDataRef.pricing_from === 'string') {
            final_sku_details.shutter_pricing_logic = skuDataRef.pricing_from;
        }

        if (typeof skuDataRef.description === 'string') {
            final_sku_details.description = skuDataRef.description;
        }

        if (skuDataRef.sku_group) {
            if (typeof skuDataRef.sku_group.id === 'string') {
                final_sku_details.group_id = skuDataRef.sku_group.id;
            }

            if (skuDataRef.sku_group.sku_sub_category) {
                if (typeof skuDataRef.sku_group.sku_sub_category.id === 'string') {
                    final_sku_details.sub_category_id = skuDataRef.sku_group.sku_sub_category.id;
                }
                if (typeof skuDataRef.sku_group.sku_sub_category.name === 'string') {
                    final_sku_details.sub_category_name = skuDataRef.sku_group.sku_sub_category.name;
                }

                if (skuDataRef.sku_group.sku_sub_category.sku_category) {
                    if (typeof skuDataRef.sku_group.sku_sub_category.sku_category.id === 'string') {
                        final_sku_details.category_id = skuDataRef.sku_group.sku_sub_category.sku_category.id;
                    }
                    if (typeof skuDataRef.sku_group.sku_sub_category.sku_category.sku_category_type_id === 'string') {
                        final_sku_details.category_type = skuDataRef.sku_group.sku_sub_category.sku_category.sku_category_type_id;
                    }
                    if (typeof skuDataRef.sku_group.sku_sub_category.sku_category.name === 'string') {
                        final_sku_details.category_name = skuDataRef.sku_group.sku_sub_category.sku_category.name;
                    }
                    if (typeof skuDataRef.sku_group.sku_sub_category.sku_category.sku_division_id === 'string') {
                        final_sku_details.division_id = skuDataRef.sku_group.sku_sub_category.sku_category.sku_division_id;
                    }

                    if (skuDataRef.sku_group.sku_sub_category.sku_category.sku_category_type &&
                        typeof skuDataRef.sku_group.sku_sub_category.sku_category.sku_category_type.pricing_dimension === 'string') {
                        final_sku_details.pricing_system = skuDataRef.sku_group.sku_sub_category.sku_category.sku_category_type.pricing_dimension;
                    }
                }
            }
        }

        if (typeof skuDataRef.store_id === 'string') {
            final_sku_details.owned_store_id = skuDataRef.store_id;
        }

        if (typeof skuDataRef.business_unit_id === 'string') {
            final_sku_details.owned_business_unit_id = skuDataRef.business_unit_id;
        }

        if (typeof skuDataRef.placement_id === 'string') {
            final_sku_details.placement_id = skuDataRef.placement_id;
        }

        if (skuDataRef.high_model_3d && skuDataRef.high_model_3d.path && typeof skuDataRef.high_model_3d.path === 'string' && skuDataRef.high_model_3d.path.length > 0) {
            final_sku_details.high_model_3d_path = skuDataRef.high_model_3d.path;
        }

        if (skuDataRef.top_view && skuDataRef.top_view.image && typeof skuDataRef.top_view.image === 'string') {
            final_sku_details.top_view_image = skuDataRef.top_view.image.substring(1);
        }

        if (skuDataRef.top_view_colour && skuDataRef.top_view_colour.image && typeof skuDataRef.top_view_colour.image === 'string') {
            final_sku_details.top_view_colour_image = skuDataRef.top_view_colour.image.substring(1);
        }

        if (skuDataRef.top_view_grayscale && skuDataRef.top_view_grayscale.image && typeof skuDataRef.top_view_grayscale.image === 'string') {
            final_sku_details.top_view_grayscale_image = skuDataRef.top_view_grayscale.image.substring(1);
        }

        if (Array.isArray(skuDataRef.additional_properties) && skuDataRef.additional_properties.length > 0) {
            let additional_properties = {};
            skuDataRef.additional_properties.forEach(item => {
                if (typeof item.key === 'string') {
                    if (typeof item.value === 'string') {
                        additional_properties[item.key] = item.value;
                    } else if (typeof item.value === 'boolean') {
                        additional_properties[item.key] = item.value.toString();
                    }
                }
            });
            final_sku_details.additional_properties = additional_properties
        }

        if (Array.isArray(skuDataRef.sales_channels) && skuDataRef.sales_channels.length > 0) {
            let sales_channels = []
            skuDataRef.sales_channels.forEach(salesChannelData => {
                let sales_channel = {};
                if (salesChannelData.id && typeof salesChannelData.id === 'string') {
                    sales_channel.id = salesChannelData.id;
                }
                if (salesChannelData.name && typeof salesChannelData.name === 'string') {
                    sales_channel.name = salesChannelData.name;
                }

                if (skuDataRef.pricing && skuDataRef.pricing.sales_channel_prices && sales_channel.id) {
                    let channelPrices = skuDataRef.pricing.sales_channel_prices[sales_channel.id];
                    if (channelPrices && Array.isArray(channelPrices)) {
                        channelPrices.forEach(priceTypeData => {
                            let price_type = {
                                price_type_id: priceTypeData.key,
                                price_type_name: priceTypeData.value.price_type.name || '',
                                price: priceTypeData.value.final_price.price || 0,
                                display_unit: priceTypeData.value.final_price.display_unit || '',
                                margin: priceTypeData.value.final_price.margin || 0,
                                tax: priceTypeData.value.final_price.tax || 0,
                                cbu: priceTypeData.value.final_price.cbu || false,
                                override_margin: priceTypeData.value.final_price.override_margin || false,
                                exclusion_list: priceTypeData.value.final_price.exclusion_list || [],
                                additional_properties: priceTypeData.value.final_price.additional_properties || {},
                                original_display_unit: "unset"
                            };
                            if(sales_channel.price_types === undefined){
                                sales_channel.price_types = [];
                            }
                            sales_channel.price_types.push(price_type);
                        });
                    }
                }
                sales_channels.push(sales_channel);
            });
            final_sku_details.sales_channels = sales_channels
        }

        if (Array.isArray(skuDataRef.Tags)) {
            let new_tags = [];
            skuDataRef.Tags.forEach(tagData => {
                if (tagData.id && typeof tagData.id === 'string') {
                    let tag_id = tagData.id;
                    let tag = tagData.tag || '';
                    let index = new_tags.findIndex(tag => tag[0] === tag_id);
                    if (index === -1) {
                        new_tags.push({ first: tag_id, second: tag });
                    }
                }
            });
            final_sku_details.tags = new_tags;
        }

        // linked_rules = []
        // if (Array.isArray(skuDataRef.linked_rules)) {
        //     skuDataRef.linked_rules.forEach(ruleData => {
        //         let new_automation_rule = new Automation_Rule();
        //         new_automation_rule.loadFromJson(ruleData);
        //         linked_rules.push(new_automation_rule);
        //     });
        // }
        // final_sku_details.linked_rules = linked_rules

        let linked_skus = []

        if (Array.isArray(skuDataRef.linked_skus)) {
            skuDataRef.linked_skus.forEach(linkedSkuData => {
                if (linkedSkuData && linkedSkuData.id) {
                    let linked_sku = {};
                    linked_sku.id = linkedSkuData.id;
                    linked_sku.type = linkedSkuData.type || '';
                    linked_sku.quantity = linkedSkuData.quantity || 0;
                    linked_skus.push(linked_sku);
                }
            });
        }

        final_sku_details.linked_skus = linked_skus;

        // Assuming skuDataRef is a JavaScript object representing the JSON-like structure

        let linked_tags = []
        // Handling linked_tags array
        if (Array.isArray(skuDataRef["linked_tags"])) {
            skuDataRef["linked_tags"].forEach(linkedTagData => {
                if (typeof linkedTagData === 'object') {
                    let linked_tag = {};

                    if (linkedTagData["id"] && typeof linkedTagData["id"] === 'string') {
                        linked_tag.id = linkedTagData["id"];
                    }
                    if (linkedTagData["type"] && typeof linkedTagData["type"] === 'string') {
                        linked_tag.type = linkedTagData["type"];
                    }

                    linked_tags.push(linked_tag);
                }
            });
        }

        final_sku_details.linked_tags = linked_tags

        // Handling valid_rule_input_details array
        // if (Array.isArray(skuDataRef["valid_rule_input_details"])) {
        //     skuDataRef["valid_rule_input_details"].forEach(inputDetail => {
        //         let store_id = inputDetail["store_id"];
        //         let designer_input_id = inputDetail["id"];

        //         if (store_id !== active_user.store_id) {
        //             let admin_cache_find = active_design.sku_designer_inputs.find(o => o.id === designer_input_id);
        //             if (!admin_cache_find) {
        //                 let designer_input = new Designer_Input();
        //                 designer_input.load(inputDetail);
        //                 active_design.sku_designer_inputs.push(designer_input);
        //             }
        //         }
        //     });
        // }

        // Handling low_model_3d
        if (skuDataRef["low_model_3d"] && typeof skuDataRef["low_model_3d"] === 'object') {
            final_sku_details.low_model_3d = skuDataRef["low_model_3d"];
        }

        // Handling component_file
        if (typeof skuDataRef["component_file"] === 'string') {
            final_sku_details.component_file = JSON.parse(skuDataRef["component_file"]) || [];
        }

        // Backward compatibility for profile and gola handles
        if (final_sku_details.category_type === "profile_and_gola_handles_hardware" || final_sku_details.category_type === "profile_handles_hardware") {
            if (final_sku_details.additional_properties["handle_type"]) {
                let handle_type_key = final_sku_details.additional_properties["handle_type"];
                if (["gola_horizontal_j", "gola_horizontal_c", "gola_vertical_single", "gola_vertical_double", "gola_horizontal_wall"].includes(handle_type_key)) {
                    final_sku_details.category_type = "gola_handles_hardware";
                } else {
                    final_sku_details.category_type = "profile_handles_hardware";
                }
            } else {
                final_sku_details.category_type = "profile_handles_hardware";
            }
        }

        // Iterating over sales_channels and updating exclusion_list
        // sales_channels.forEach(salesChannel => {
        //     salesChannel.price_types.forEach(priceType => {
        //         let find_old_category_type = priceType.exclusion_list.find(type => type === "profile_and_gola_handles_hardware");

        //         if (find_old_category_type) {
        //             // Remove old category and add new ones
        //             let exclusionIndex = priceType.exclusion_list.indexOf(find_old_category_type);
        //             if (exclusionIndex !== -1) {
        //                 priceType.exclusion_list.splice(exclusionIndex, 1);  // Remove old category
        //             }
        //             priceType.exclusion_list.push("profile_handles_hardware", "gola_handles_hardware");
        //         }
        //     });
        // });

        return final_sku_details


    } catch (error) {
        console.error("Error loading SKU data:", error);
    }
}


export const get_sku_details_core_format = async (sku_id) => {
    try {
        if (window.portal === "design") {
            return JSON.parse(window.Module.get_sku_details_from_core(sku_id));
        } else if (window.portal === "admin") {
            let sku_details = {}
            let user = window.get_user()
            let identifiers = JSON.stringify({ id: sku_id })
            let business_unit_id = user.current_business_unit_id
            let body = { identifiers, business_unit_id }
            let temp_sku_details = await general_fetch({ url: 'sku/get', body });
            temp_sku_details = temp_sku_details[0];
            sku_details = loadFromAdminJson(temp_sku_details)
            return sku_details
        } else {
            throw `Portal not found ${window.portal}`;
        }

    } catch (err) {
        console.error(`Error in get_sku_details_core_format `, err)
    }
}