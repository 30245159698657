import React, { useEffect, useState } from "react"
import { isNonEmptyArray } from "../utils/array"
import ConnectorCanvasComponent from "./ConnectorCanvasComponent"
import Icons from "../ui_component_library/Icons"
import { ButtonsDropdown } from "../ui_component_library/ButtonsDropdown"
import { get_sku_details_core_format, get_sku_display_url } from "../utils/sku"
import './styles.css'
import { Tooltip } from "antd"

const DefaultApplicatorCardWrapper = ({children}) => {
	return (
		<React.Fragment>
			{children}
		</React.Fragment>
	)
}

export const ApplicatorCard = ({o, is_selected, edit_selected_applicator, deselect_applicator, select_applicator, dropdown_buttons, ApplicatorCardWrapper=DefaultApplicatorCardWrapper, ApplicatorCardWrapperProps, sku_cache,  ...props}) => {

	const [ sku_details, set_sku_details ] = useState({});

	useEffect(() => {
		if(o.sku_id && o.sku_id != sku_details.id){
			if(sku_cache && sku_cache[o.sku_id]){
				set_sku_details(sku_cache[o.sku_id]);
			}else{
				(async() => {
					let _sku_details = await (get_sku_details_core_format(o.sku_id));
					set_sku_details(_sku_details);
				})()
			}
		}
	}, [o]);

	return (
		<ApplicatorCardWrapper {...ApplicatorCardWrapperProps} sku_details={sku_details}>
		<div className='cam_applicator_card' {...props}>
			<div className='flex_between inf-gap-2'>
				<div style={{fontWeight: 700}} >{o.name}</div>
				<div className="flex_property" style={{ gap: '8px' }} onClick={(e) => e.stopPropagation()}>
					<Tooltip trigger={['click']} placement="bottomLeft" overlayStyle={{maxWidth: 'unset', width: '300px'}} overlayInnerStyle={{padding: '16px'}} title={
						<div className="flex_column inf-gap-3">
							<div className="flex_between"><b>Status:</b> <div>{o.is_fe ? "Created within Design" : "Published on Admin"}</div></div>
							<div className="flex_between"><b>ID:</b> <div>{o.id}</div></div>
						</div>
					}>
						<Icons name={'info'} className={'cp inf-px-1'} />
					</Tooltip>
					{edit_selected_applicator ? <Icons onClick={() => edit_selected_applicator(o)} name={'edit'} title={"Edit"} style={{ fontSize: '16px', cursor: 'pointer' }} /> : '' }
					{is_selected?(<img src="/resources/icons/remove.svg" onClick={() => deselect_applicator(o?o.id:"")} style={{cursor:"pointer"}} />):''}
					{
						isNonEmptyArray(dropdown_buttons) ? <ButtonsDropdown button_list={dropdown_buttons} customToggle={
							<div className='inf-p-1 rounded inf-border'>
								<Icons name={'horizontal_options'} style={{fontSize: '16px'}} />
							</div>
						} /> : ''
					}
				</div>
			</div>

			<div className='flex_between' style={{gap: '8px'}}>
				<div className='lines1_elipsis' title={sku_details && sku_details.sku_name || "N/A"}>
					{sku_details && sku_details.sku_name || "N/A"}
				</div>

				<div style={{whiteSpace: 'nowrap'}}>Qty: {o && o.offsets && o.offsets.length || 0}</div>
			</div>

			<div style={{display:"grid", gridTemplateColumns: "2fr 5fr",gap:8}} onClick={() => {if(select_applicator){select_applicator(o?o.id:"")}}}>
				<div style={{alignContent:"center",textAlign:"center"}}><img style={{height:40}} src={sku_details && sku_details.display_pic && get_sku_display_url(sku_details.display_pic) || ''} /></div>
				<div style={{textAlign:"center"}}>
					<ConnectorCanvasComponent 
						curr_applicator={o}
						with_controller={false}
						canvas_width={o.applicator_type=="dowel_hinge"?250:300} canvas_height={o.applicator_type=="dowel_hinge"?120:300}
						panel_width={500} panel_depth={500} panel_thickness={30}
					/>
				</div>
			</div>
		</div>
		</ApplicatorCardWrapper>
	)
}

export default ApplicatorCard